export interface IUser {
  id: number,
  name: string,
  accessToken: string,
  expires:number,
  loginTime: string,
  isLogin:boolean

}
interface IState {
  user: IUser
}
const initUserState: IState = {
  user: {
    // id: 0,
    // name: '',
    // accessToken: '',
    // expires:0,
    // loginTime: '',
    isLogin:false
  }
}
export enum IUserActionType {
  INIT,
  CHANGE,
  ISLOGIN
}
const user = (state: IState = initUserState, action: { type: IUserActionType, payload: any }) => {
  switch (action.type) {
    case IUserActionType.INIT:
      return state
    case IUserActionType.CHANGE:
      return { ...state, ...action.payload }
    case IUserActionType.ISLOGIN:
        return { ...state, ...action.payload }
    default:
      return state
  }
}
export default user