import styles from './index.module.less';
import { DotLoading } from 'antd-mobile'
export default function Comp () {
  return (
    <div className={styles.container}>
      <span style={{ fontSize: 18 }}>
        Loading <DotLoading />
      </span>
    </div>
  )
}