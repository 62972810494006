import { createStore, applyMiddleware,combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "./reducer";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
export type rootState = ReturnType<typeof reducers>


const persistConfig = {
  key: 'Word100Root',
  storage,
}


const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware()),)

let persistor = persistStore(store)

export default store
export {persistor};




